import React from 'react';
import { SvgProps } from './icon.types';

function TransitionIcon({ title, fillColor, ...props }: SvgProps) {
  return (
    <svg height="21" viewBox="0 0 240 234" width="22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="
M115.460632,235.000000 
	C114.998810,226.184280 114.873871,217.365982 115.057533,208.554108 
	C115.130676,205.044708 113.773132,203.627640 110.387779,202.966705 
	C83.238670,197.666199 64.523537,174.414917 64.918228,147.022537 
	C64.974823,143.094498 66.169426,141.899307 70.056427,141.926880 
	C85.341049,142.035278 98.543121,146.974747 109.416336,157.877884 
	C111.016563,159.482513 112.910667,160.794083 115.015373,161.694458 
	C115.191277,153.440262 115.020607,145.732834 114.849937,138.025406 
	C101.989738,132.728226 93.825226,122.940567 90.100113,109.942574 
	C82.068123,81.916679 91.035042,58.212162 110.056641,37.503868 
	C113.272865,34.002460 116.995514,30.966219 120.636604,27.573013 
	C132.115204,35.936741 141.040939,45.710388 146.900055,57.806671 
	C155.419861,75.396057 158.024353,93.695190 151.224304,112.653870 
	C147.717438,122.431061 141.492111,130.359085 132.713043,135.265259 
	C126.812248,138.562927 126.270790,142.265076 127.186249,146.429413 
	C129.836441,144.335800 133.278198,142.114746 136.118317,139.288971 
	C150.106049,125.371880 166.618881,118.082649 186.586090,118.910675 
	C190.710983,119.081734 192.086609,120.392708 192.056671,124.483032 
	C191.845993,153.268906 174.052368,181.042801 144.229111,191.020050 
	C140.332535,192.323639 136.282318,193.249710 132.239044,194.008514 
	C128.699692,194.672775 126.790527,195.881165 126.901253,200.076111 
	C127.200089,211.397980 127.004082,222.732925 127.002213,234.531342 
	C123.307091,235.000000 119.614182,235.000000 115.460632,235.000000 
M110.048401,56.536690 
	C106.840500,64.096054 102.579407,71.396492 100.644104,79.268715 
	C96.623711,95.622383 98.749260,111.005386 112.441811,122.752800 
	C115.526459,125.399261 118.966125,129.358719 123.686081,126.941216 
	C131.378128,123.001450 137.187103,117.039261 140.151184,108.660507 
	C145.290695,94.132240 143.568970,80.023643 137.558670,66.192741 
	C133.780960,57.499466 128.214874,50.078583 121.018784,42.705273 
	C117.245781,47.460556 113.870773,51.714226 110.048401,56.536690 
M128.200180,176.747864 
	C128.200180,178.702271 128.200180,180.656662 128.200180,182.629074 
	C152.446030,180.852020 177.499542,158.161957 179.363892,131.741714 
	C162.952698,129.097900 133.956314,148.764191 128.200180,176.747864 
M81.872986,168.531113 
	C88.970749,181.453537 99.716179,189.123871 114.475632,191.590942 
	C111.278969,171.559494 96.074478,156.521774 76.913002,154.727539 
	C78.497101,159.311401 79.980759,163.604599 81.872986,168.531113 
M115.517960,164.517654 
	C115.517960,164.517654 115.514717,164.486496 115.517960,164.517654 
z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="5.5"
      />
      <path
        d="
M141.531342,1.000000 
	C142.588272,1.636338 143.064880,2.593030 143.787201,2.844847 
	C145.489136,3.438175 147.329041,3.620382 149.075500,4.104631 
	C152.445801,5.039132 154.350082,7.523562 153.467133,10.756805 
	C152.550751,14.112450 150.223755,16.132854 145.965530,15.404082 
	C137.824478,14.010783 129.563400,12.111693 121.405304,12.338119 
	C111.746689,12.606191 101.951118,14.126101 92.558090,16.475479 
	C73.650406,21.204657 57.227314,31.152472 43.790051,45.012589 
	C27.135019,62.191730 16.921755,82.837013 13.861110,106.998512 
	C11.121819,128.623138 15.100948,148.826797 24.170134,168.313705 
	C24.708929,169.471420 25.476500,170.522659 26.576477,172.354187 
	C27.245338,170.057266 27.531473,168.472122 28.160080,167.036987 
	C29.626846,163.688324 32.408199,162.092514 35.819412,163.246078 
	C38.876740,164.279968 40.443146,166.695129 39.483299,170.320419 
	C37.620884,177.354614 35.876167,184.423370 34.268997,191.519989 
	C33.667717,194.175018 32.736168,195.168259 29.837664,194.333725 
	C23.308481,192.453857 16.638903,191.060303 10.114383,189.166000 
	C4.611477,187.568314 2.651847,184.940369 4.221821,181.160721 
	C5.892203,177.139389 9.235239,176.241196 13.272050,177.835571 
	C13.651724,177.985519 14.208055,177.688187 15.186448,177.503235 
	C13.015158,172.227188 10.592707,167.218903 8.885675,161.977600 
	C6.590234,154.929626 4.860748,147.697922 2.839951,140.558762 
	C2.679283,139.991165 2.142294,139.530090 1.390097,139.009750 
	C1.000000,126.979103 1.000000,114.958214 1.391984,102.455284 
	C2.180992,101.131859 2.786850,100.327698 2.944436,99.443626 
	C6.691193,78.423889 15.354090,59.562111 29.213972,43.453945 
	C46.796814,23.018904 68.830513,9.485696 95.377029,3.653595 
	C97.032448,3.289911 98.464195,1.908119 100.000000,0.999999 
	C113.687561,1.000000 127.375122,1.000000 141.531342,1.000000 
z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="5.5"
      />
      <path
        d="
M241.000000,139.531342 
	C240.343826,140.967010 239.423462,141.847031 239.072067,142.914383 
	C235.309052,154.344452 232.618301,166.245972 227.711853,177.153839 
	C220.039215,194.211456 207.429459,207.653961 192.583832,218.996918 
	C189.284378,221.517914 186.133133,222.076218 183.435516,218.776474 
	C180.720459,215.455383 182.038406,212.111725 184.906342,209.514664 
	C188.571045,206.196060 192.630859,203.303406 196.201981,199.893677 
	C209.899872,186.814804 219.158752,171.182053 224.319138,152.826782 
	C227.772247,140.544266 229.438828,128.244537 228.997986,115.579872 
	C228.435883,99.431831 223.498611,84.502243 216.044189,68.904755 
	C215.063766,71.227364 214.297363,72.542671 213.949112,73.960678 
	C212.846756,78.449471 209.399612,78.701416 206.125900,77.727837 
	C202.957275,76.785500 201.779602,74.273445 202.838562,70.637032 
	C204.773758,63.991550 206.593048,57.275410 207.813187,50.472610 
	C208.531586,46.467293 210.682388,46.541573 213.458908,47.217060 
	C219.705246,48.736702 225.902512,50.458168 232.149933,51.973083 
	C236.278320,52.974155 238.799942,55.107819 237.871704,59.668846 
	C236.914978,64.369934 233.284241,64.228020 229.652817,63.815643 
	C228.900162,63.730171 228.174850,63.404015 226.575485,62.937531 
	C227.303604,64.980782 227.782761,66.285774 228.234787,67.600105 
	C231.876205,78.188110 235.504822,88.780540 239.171188,99.359886 
	C239.371262,99.937141 239.873413,100.409714 240.617493,100.965508 
	C241.000000,113.687561 241.000000,126.375122 241.000000,139.531342 
z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="5.5"
      />
    </svg>
  );
}

export { TransitionIcon };
