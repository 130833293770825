import React from 'react';
import { SvgProps } from './icon.types';

function TracteurIcon({ title, fillColor, ...props }: SvgProps) {
return (
  <svg viewBox="0 0 25 24" width="25" height="24" fill='none' xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.5 11L21.5 11.9C22.1 11.9 22.4 12.4 22.3 13L21.5 18H20.5M16.5 18H11.5" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 5C18.2348 5 17.9804 5.10536 17.7929 5.29289C17.6054 5.48043 17.5 5.73478 17.5 6V11.573M3.5 4H12.5L13.5 11.246M4.5 11V4M7.5 15H7.51M8.5 10.1V4" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.5 20C19.6046 20 20.5 19.1046 20.5 18C20.5 16.8954 19.6046 16 18.5 16C17.3954 16 16.5 16.8954 16.5 18C16.5 19.1046 17.3954 20 18.5 20Z" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.5 20C10.2614 20 12.5 17.7614 12.5 15C12.5 12.2386 10.2614 10 7.5 10C4.73858 10 2.5 12.2386 2.5 15C2.5 17.7614 4.73858 20 7.5 20Z" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>

  </svg>
);
}

export { TracteurIcon };
