/* eslint-disable react-hooks/rules-of-hooks */
import React, { ComponentProps } from 'react';
import ThematicButton from '@components/buttons/ThematicButton/ThematicButton.component';
import { thematicInUrl } from '@search/business/search.types';
import { PlcColor } from '@styles/colors';
import { usePlcRouter } from '@utils/customHooks';
import { castUrlParamToString, urlEnum } from '@utils/url';
import classnames from 'classnames';
import styles from './ThematicNav.module.scss';

interface Props extends ComponentProps<'div'> {
  isSmartphoneOrTablet?: boolean;
}

function isActive(thematicPage: string) {
  const { asPath, pathname, query } = usePlcRouter();
  let thematicFilter;
  if (pathname === urlEnum.search && query.thematiques) {
    thematicFilter = castUrlParamToString(query.thematiques);
  }
  return asPath.includes(thematicPage) || (pathname === urlEnum.search && thematicPage === thematicFilter);
}

const ThematicNav = ({ className, isSmartphoneOrTablet = false }: Props) => {
  const rootClassName = classnames(className, 'plc-thematic-header');
  const buttonClassName = 'plc-mr-s';

  return (
    <div className={rootClassName}>
      <div>
        <ThematicButton
          className={buttonClassName}
          classNameText={styles.thematiqueText}
          fillColor={isActive(thematicInUrl.farmManagementThematic) ? PlcColor.WHITE : PlcColor.GREEN}
          isActive={isActive(thematicInUrl.farmManagementThematic)}
          isHeader
          isSmartphoneOrTablet={isSmartphoneOrTablet}
          label="thematic.header.gestion"
          url={urlEnum.thematiqueGestion}
        />
        <ThematicButton
          className={buttonClassName}
          classNameText={styles.thematiqueText}
          fillColor={isActive(thematicInUrl.installationTransmission) ? PlcColor.WHITE : PlcColor.GREEN}
          isActive={isActive(thematicInUrl.installationTransmission)}
          isHeader
          isSmartphoneOrTablet={isSmartphoneOrTablet}
          label="thematic.header.installation"
          url={urlEnum.thematiqueInstallation}
        />
        <ThematicButton
          className={buttonClassName}
          classNameText={styles.thematiqueText}
          fillColor={isActive(thematicInUrl.transitions) ? PlcColor.WHITE : PlcColor.GREEN}
          isActive={isActive(thematicInUrl.transitions)}
          isHeader
          isSmartphoneOrTablet={isSmartphoneOrTablet}
          label="thematic.header.transitions"
          url={urlEnum.thematiqueTransitions}
        />
        <ThematicButton
          classNameText={styles.thematiqueText}
          fillColor={isActive(thematicInUrl.innovationThematic) ? PlcColor.WHITE : PlcColor.GREEN}
          isActive={isActive(thematicInUrl.innovationThematic)}
          isHeader
          isSmartphoneOrTablet={isSmartphoneOrTablet}
          label="thematic.header.innovation"
          url={urlEnum.innovations}
        />
      </div>
    </div>
  );
};

export default ThematicNav;
