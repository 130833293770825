import React from 'react';
import { SvgProps } from './icon.types';

function MagnifierIcon({ title, fillColor = '#2D3049', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M10 16a6 6 0 11.01-12.01A6 6 0 0110 16zm12 4.59l-5.69-5.69a8 8 0 10-1.41 1.41L20.59 22 22 20.59z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export { MagnifierIcon };
