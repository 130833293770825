/* eslint-disable react/jsx-no-bind */
import { TagCommanderService } from 'features/tagCommander/business/TagCommander.service';
import { useTranslation } from 'next-i18next';
import React, { ComponentPropsWithRef, FC, MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import { buildProfileMenus } from '@auth/business/auth.utils';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { IconButton } from '@components/buttons/IconButton/IconButton.component';
import { ExitIcon } from '@components/icons/Exit.icon';
import { ProfileIcon } from '@components/icons/Profile.icon';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { usePlcRouter } from '@utils/customHooks';
import classnames from 'classnames';
import styles from './ProfileButton.module.scss';

export interface ProfileButtonProps extends ComponentPropsWithRef<'button'> {
  isAuthenticated: boolean;
  onSignOut: () => void;
  onSignIn: () => void;
}

const MyAccountButton: FC<ComponentPropsWithRef<'button'>> = ({ className, onClick }) => {
  const isMobile = useIsBreakpointDown(Breakpoint.s);
  const iconSize = isMobile ? 20 : 24;
  return (
    <PrimaryButton
      className={classnames(className, 'account-btn', 'header-button')}
      isBleuTurquoise
      isExtraSmall={isMobile}
      isSmall={!isMobile}
      isWithIcon
      onClick={onClick}
      textProps={{ i18nKey: 'common.my-account.header', variant: 'menu-link' }}
    >
      <ProfileIcon className={styles.accountIcon} fillColor={PlcColor.WHITE} height={iconSize} width={iconSize} />
    </PrimaryButton>
  );
};

const ProfileButton: FC<ProfileButtonProps> = ({
  isAuthenticated,
  onSignIn,
  onSignOut,
  onClick,
  className,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const { route } = usePlcRouter();
  const { t } = useTranslation(['auth']);
  function closeDropdown() {
    setOpen(false);
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (node.current && node.current.contains(e.target as Node)) {
      return;
    }
    closeDropdown();
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  function handleProfileButtonClick(e: ReactMouseEvent<HTMLButtonElement>) {
    if (isAuthenticated) {
      setOpen(!isOpen);
    } else {
      onSignIn();
    }
    if (onClick) onClick(e);
  }

  const stateClassName = classnames(isOpen ? 'opened' : 'closed');
  const rootClassName = classnames(styles.profileBtn, className);
  const dropdownClassName = classnames('plc-dropdown', stateClassName);
  return (
    <div ref={node} className={rootClassName}>
      <IconButton aria-haspopup="true" onClick={handleProfileButtonClick} {...rest}>
        {isAuthenticated ? (
          <MyAccountButton />
        ) : (
          <PrimaryButton
            className={classnames('connect-btn', 'header-button')}
            isBleuTurquoise
            isSmall
            textProps={{ i18nKey: 'common.connect.header', variant: 'menu-link' }}
          />
        )}
      </IconButton>
      <ul className={dropdownClassName}>
        {buildProfileMenus(route, 'small', closeDropdown).map((m, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i}>{m}</li>
        ))}

        <li>
          <PrimaryButton
            isDarkGreen
            onClick={
              // () => {
              // Disaled because throw Error: A cross-origin error was thrown. React doesn't have access to the actual error object in development. See https://fb.me/react-crossorigin-error for more information.
              (event) => {
                TagCommanderService.triggerEvent('click', event.currentTarget, {
                  button_name: t('auth.disconnection'),
                });
                onSignOut();
                closeDropdown();
              }
            }
            textProps={{ i18nKey: 'auth.disconnection' }}
          >
            <ExitIcon className={styles.exitIcon} fillColor={PlcColor.WHITE} height={24} width={24} />
          </PrimaryButton>
        </li>
      </ul>
    </div>
  );
};

export { ProfileButton, MyAccountButton };
