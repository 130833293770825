import React from 'react';
import { SvgProps } from './icon.types';

function VectorIcon({ title, fillColor, ...props }: SvgProps) {
return (
  <svg viewBox="0 0 21 22" width="21" height="22" fill='none' xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.21432 21.1428H12.7857M10.5 2.85709V1.71423M16.2143 5.14281L17.3572 3.99995M4.78575 5.14281L3.64289 3.99995M16.2143 15.4285L17.3572 16.5714M4.78575 15.4285L3.64289 16.5714M2.50003 9.71423H1.35718M19.6429 9.71423H18.5M10.5 5.71423C11.6604 5.71352 12.7869 6.10523 13.6965 6.82572C14.606 7.5462 15.2452 8.5531 15.5102 9.6828C15.7751 10.8125 15.6503 11.9986 15.1559 13.0484C14.6614 14.0981 13.8266 14.9498 12.7869 15.4651L12.7857 16.5714C12.7857 17.1776 12.5449 17.759 12.1163 18.1876C11.6876 18.6163 11.1062 18.8571 10.5 18.8571C9.89383 18.8571 9.31245 18.6163 8.88379 18.1876C8.45514 17.759 8.21432 17.1776 8.21432 16.5714V15.4651C7.17473 14.9499 6.3399 14.0983 5.84548 13.0486C5.35106 11.999 5.2261 10.813 5.4909 9.68336C5.7557 8.55372 6.3947 7.54682 7.30408 6.82625C8.21346 6.10568 9.33978 5.71378 10.5 5.71423Z" stroke={fillColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
}

export { VectorIcon };
