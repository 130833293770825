import React from 'react';
import { SvgProps } from './icon.types';

function ProfileIcon({ title, fillColor = '#2E3049', ...props }: SvgProps) {
  return (
    <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g id="Group-5" transform="translate(4.000000, -4.000000)" />
        <path
          d="M12,4 C9.794,4 8,5.794 8,8 C8,10.206 9.794,12 12,12 C14.206,12 16,10.206 16,8 C16,5.794 14.206,4 12,4 M12,14 C8.691,14 6,11.309 6,8 C6,4.691 8.691,2 12,2 C15.309,2 18,4.691 18,8 C18,11.309 15.309,14 12,14"
          fill={fillColor}
          id="Fill-1"
        />
        <path
          d="M20,22 L18,22 C18,20.605 16.533,18 13,18 L11,18 C7.467,18 6,20.605 6,22 L4,22 C4,19.484 6.433,16 11,16 L13,16 C17.567,16 20,19.484 20,22"
          fill={fillColor}
          id="Fill-3"
        />
      </g>
    </svg>
  );
}

export { ProfileIcon };
