import React from 'react';
import { SvgProps } from './icon.types';

function ExitIcon({ title, fillColor = '#2D3049', ...props }: SvgProps) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M7 4H4v16h3v2H2V2h5v2zm-1 7h12.171L14.88 7.709l1.413-1.415L22 12.002l-5.706 5.707-1.414-1.415 3.292-3.292L6 13v-2z"
        fill={fillColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export { ExitIcon };
