/* eslint-disable no-nested-ternary */
import React from 'react';
import { ChevronRightIcon } from '@components/icons/ChevronRight.icon';
import { FarmIcon } from '@components/icons/FarmIcon';
import { TracteurIcon } from '@components/icons/TracteurIcon';
import { VectorIcon } from '@components/icons/VectorIcon';
import { Text } from '@components/Text/Text.component';
import { TransitionIcon } from '@icons/TransitionIcon';
import { PlcColor } from '@styles/colors';
import { usePlcRouter } from '@utils/customHooks';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';
import { PlcNextPage } from 'features/business/app.types';
import styles from './ThematicButton.module.scss';

interface Props {
  label?: string;
  url?: urlEnum;
  className?: string;
  classNameText?: string;
  fillColor?: string;
  isSmartphoneOrTablet?: boolean;
  isActive?: boolean;
  isHeader?: boolean;
}

const ThematicButton: PlcNextPage<Props> = ({
  isActive,
  isHeader = false,
  label,
  url,
  classNameText,
  fillColor,
  isSmartphoneOrTablet = false,
  className,
}) => {
  const { route, push } = usePlcRouter();
  const isThematicOrJournalPage =
    route.includes('/thematique') || route === urlEnum.search || route.includes(urlEnum.innovations);
  let icon;

  if (url === urlEnum.thematiqueGestion) {
    icon = <TracteurIcon fillColor={fillColor} />;
  }
  if (url === urlEnum.thematiqueTransitions) {
    icon = <TransitionIcon fillColor={fillColor} />;
  }
  if (url === urlEnum.innovations) {
    icon = <VectorIcon fillColor={fillColor} />;
  }
  if (url === urlEnum.thematiqueInstallation) {
    icon = <FarmIcon fillColor={fillColor} />;
  }

  const mainClassName = classnames(
    className,
    isHeader ? styles.headerButton : styles.bannerButton,
    isThematicOrJournalPage ? (isActive ? styles.isActive : styles.nonActive) : styles.nonActive,
    isSmartphoneOrTablet ? styles.isMobile : ''
  );

  return (
    <button aria-label={label} className={mainClassName} onClick={() => push(`${url}`)} type="button">
      {icon}

      <Text className={classNameText} flavour={isActive ? 'light' : 'meteo-dark-green'} i18nKey={label} variant="h6" />
      {isSmartphoneOrTablet && (
        <ChevronRightIcon
          className="plc-mr-up-l-m plc-mr-up-m-s plc-ml-auto"
          fillColor={isActive ? PlcColor.WHITE : PlcColor.GREEN}
          height={12}
          stroke={isActive ? PlcColor.WHITE : PlcColor.GREEN}
          width={12}
        />
      )}
    </button>
  );
};

export default ThematicButton;
