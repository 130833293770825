import { PlcAnchor } from '../PlcAnchor/PlcAnchor.component';
import { HamburgerProps } from './Hamburger.component';
import React, { ComponentPropsWithoutRef, ComponentPropsWithRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthModal } from '@auth/business/AuthModal.hook';
import { MyAccountButton, ProfileButtonProps } from '@auth/components/ProfileButton/ProfileButton.component';
import { MagnifierIcon } from '@components/icons/Magnifier.icon';
import { LogoProps } from '@components/Logo/Logo.component';
import ThematicNav from '@components/ThematicNav/ThematicNav.component';
import { MenuProps } from '@layout/components/Menu/Menu.component';
import { Navigation } from '@layout/components/Navigation/Navigation.component';
import { SearchFormProps } from '@search/components/SearchForm/SearchForm.component';
import { PlcDispatch, RootState } from '@store/store';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { PlcColor } from '@styles/colors';
import { ProfileTabEnum } from '@user/business/User.enums';
import { useIsSSO, userSelectors } from '@user/business/User.store';
import { usePlcRouter } from '@utils/customHooks';
import { urlEnum } from '@utils/url';
import dynamic from 'next/dynamic';

const PwaBanner: any = dynamic<ComponentPropsWithoutRef<'div'>>(
  () => import('@components/PwaBanner/PwaBanner.component').then((module) => module.default),
  {
    ssr: false,
  }
);

const Banner: any = dynamic<ComponentPropsWithoutRef<'div'>>(
  () => import('@components/Banner/Banner.component').then((module) => module.Banner),
  {
    ssr: false,
  }
);

const IconButton: any = dynamic<ComponentPropsWithRef<'button'>>(
  () => import('@components/buttons/IconButton/IconButton.component').then((module) => module.IconButton),
  {
    ssr: false,
  }
);

const ProfileButton: any = dynamic<ProfileButtonProps>(
  () => import('@auth/components/ProfileButton/ProfileButton.component').then((module) => module.ProfileButton),
  {
    ssr: false,
  }
);

const Logo: any = dynamic<LogoProps>(() => import('@components/Logo/Logo.component').then((module) => module.Logo), {
  ssr: false,
});

const SearchForm: any = dynamic<SearchFormProps>(
  () => import('@search/components/SearchForm/SearchForm.component').then((module) => module.SearchForm),
  {
    ssr: false,
  }
);

const Menu: any = dynamic<MenuProps>(
  () => import('@layout/components/Menu/Menu.component').then((module) => module.Menu),
  {
    ssr: false,
  }
);

const Hamburger: any = dynamic<HamburgerProps>(
  () => import('./Hamburger.component').then((module) => module.Hamburger),
  {
    ssr: false,
  }
);

const Subscribe: any = dynamic<{}>(() => import('./Subscribe.component').then((module) => module.Subscribe), {
  ssr: false,
});

function focusSearchInput() {
  const input: HTMLInputElement | null = document.querySelector('.search-form input');
  if (input) {
    input.focus();
  }
}

const Header: React.FunctionComponent = () => {
  const {
    layout: { toggleMenuOpened },
    auth: { signOut },
  } = useDispatch<PlcDispatch>();
  const { showSignInModal } = useAuthModal();
  const [isSearchFormOpened, setIsSearchFormOpened] = useState(false);
  const { isMenuOpened, isAuthenticated } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isMenuOpened: state.layout.isMenuOpened,
  }));

  const isPro = useSelector(userSelectors.isPro);
  const isMobile = useIsBreakpointDown(Breakpoint.l);

  const { route, push } = usePlcRouter();
  const renderPwaBanner = isMobile && route === urlEnum.home;

  const isSSO = useIsSSO();

  function onSearchClick() {
    if (!isSearchFormOpened) {
      focusSearchInput();
    }
    setIsSearchFormOpened(!isSearchFormOpened);
  }

  const headerClassName = `plc-header`;
  return (
    <>
      <header className={headerClassName}>
        {isSSO ? (
          <div className="plc-header-wrapper wrapper-s flex align-center">
            <Navigation isSSO />
          </div>
        ) : (
          <>
            <div className="plc-header-wrapper wrapper-l flex space-between align-center">
              <PlcAnchor linkProps={{ as: urlEnum.home, href: urlEnum.home }}>
                <Logo isPro={isPro} />
              </PlcAnchor>
              {isMobile && isAuthenticated && (
                <MyAccountButton
                  onClick={() => {
                    push(`${urlEnum.profile}/${ProfileTabEnum.profile}`);
                  }}
                />
              )}
              <nav>
                <IconButton className="search-btn" onClick={onSearchClick}>
                  <MagnifierIcon fillColor={PlcColor.GREEN} height={24} width={24} />
                </IconButton>

                <span className="profile-separator" />
                <Navigation />
                <span className="profile-separator" />
                <ProfileButton
                  className="none inline-block-up-l"
                  isAuthenticated={isAuthenticated}
                  onSignIn={showSignInModal}
                  onSignOut={signOut}
                />
                {!isPro && <Subscribe />}
                {isMobile && <Hamburger isMenuOpened={isMenuOpened} toggleMenuOpened={toggleMenuOpened} />}
              </nav>
            </div>
            {!isMobile && <ThematicNav className="thematic-header" />}
          </>
        )}
      </header>
      <Banner />
      {renderPwaBanner && <PwaBanner />}
      <SearchForm isOpened={isSearchFormOpened} onClose={() => setIsSearchFormOpened(false)} />
      <Menu isAuthenticated={isAuthenticated} onSignIn={showSignInModal} onSignOut={signOut} />
    </>
  );
};

export { Header };
